var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("div", { staticClass: "search-attr" }, [
        _c(
          "div",
          [
            _c("a-input", {
              staticStyle: { width: "180px" },
              attrs: { allowClear: "", size: "small", placeholder: "订单号" },
              model: {
                value: _vm.params.orderNo,
                callback: function($$v) {
                  _vm.$set(_vm.params, "orderNo", $$v)
                },
                expression: "params.orderNo"
              }
            }),
            _c("a-input", {
              staticStyle: { width: "130px", margin: "5px" },
              attrs: { allowClear: "", size: "small", placeholder: "物流单号" },
              model: {
                value: _vm.params.expressNo,
                callback: function($$v) {
                  _vm.$set(_vm.params, "expressNo", $$v)
                },
                expression: "params.expressNo"
              }
            }),
            _c("a-input", {
              staticStyle: { width: "180px" },
              attrs: { allowClear: "", size: "small", placeholder: "退款编号" },
              model: {
                value: _vm.params.returnsNo,
                callback: function($$v) {
                  _vm.$set(_vm.params, "returnsNo", $$v)
                },
                expression: "params.returnsNo"
              }
            }),
            _c("a-input", {
              staticStyle: { "margin-left": "10px", width: "155px" },
              attrs: {
                allowClear: "",
                size: "small",
                placeholder: "买家/uId/昵称/店铺"
              },
              model: {
                value: _vm.params.buyerUserName,
                callback: function($$v) {
                  _vm.$set(_vm.params, "buyerUserName", $$v)
                },
                expression: "params.buyerUserName"
              }
            }),
            _c("a-input", {
              staticStyle: { "margin-left": "10px", width: "155px" },
              attrs: {
                allowClear: "",
                size: "small",
                placeholder: "卖家/uId/昵称/店铺"
              },
              model: {
                value: _vm.params.sellerUserName,
                callback: function($$v) {
                  _vm.$set(_vm.params, "sellerUserName", $$v)
                },
                expression: "params.sellerUserName"
              }
            }),
            _c(
              "a-select",
              {
                staticStyle: { width: "150px", "margin-left": "10px" },
                attrs: {
                  allowClear: "",
                  size: "small",
                  placeholder: "订单状态"
                },
                model: {
                  value: _vm.params.status,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "status", $$v)
                  },
                  expression: "params.status"
                }
              },
              _vm._l(_vm.orderState, function(item, key) {
                return _c(
                  "a-select-option",
                  { key: key, attrs: { value: item.value } },
                  [_vm._v(_vm._s(item.label))]
                )
              }),
              1
            ),
            _c(
              "a-select",
              {
                staticStyle: { width: "150px", "margin-left": "10px" },
                attrs: {
                  allowClear: "",
                  size: "small",
                  placeholder: "退款状态"
                },
                model: {
                  value: _vm.params.returnsReviewStatus,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "returnsReviewStatus", $$v)
                  },
                  expression: "params.returnsReviewStatus"
                }
              },
              _vm._l(_vm.resetOrderState, function(item, key) {
                return _c(
                  "a-select-option",
                  { key: key, attrs: { value: item.value } },
                  [_vm._v(_vm._s(item.label))]
                )
              }),
              1
            ),
            _c(
              "a-button",
              {
                staticStyle: { "margin-left": "10px" },
                attrs: { size: "small" },
                on: { click: _vm.search }
              },
              [_vm._v("搜索")]
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "table-content" },
        [
          _c("a-table", {
            attrs: {
              pagination: _vm.pagination,
              loading: _vm.loading,
              columns: _vm.columns,
              "data-source": _vm.data,
              rowKey: function(record, n) {
                return n
              }
            },
            on: { change: _vm.changePage },
            scopedSlots: _vm._u([
              {
                key: "orderNoSlot",
                fn: function(row) {
                  return _c("div", {}, [
                    _c(
                      "span",
                      {
                        staticClass: "order-text",
                        on: {
                          click: function($event) {
                            return _vm.handleGoToOrderListPage(row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(row.orderNo))]
                    )
                  ])
                }
              },
              {
                key: "buyerUserNameSlot",
                fn: function(row) {
                  return _c("div", {}, [
                    _c("div", [_vm._v(_vm._s(row.buyerUserName))]),
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "10px" } },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: { size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.handleShowChatList(row)
                              }
                            }
                          },
                          [_vm._v("查看聊天记录")]
                        )
                      ],
                      1
                    )
                  ])
                }
              },
              {
                key: "orderKindSlot",
                fn: function(row) {
                  return _c("div", {}, [
                    row.orderKind == 0
                      ? _c("div", [_vm._v("竞拍单")])
                      : _vm._e(),
                    row.orderKind == 1
                      ? _c("div", [_vm._v("一口价")])
                      : _vm._e()
                  ])
                }
              },
              {
                key: "snapshotSlot",
                fn: function(row) {
                  return _c("div", {}, [
                    _c(
                      "div",
                      { staticClass: "product-img-list" },
                      _vm._l(row.coverImages, function(item, index) {
                        return _c(
                          "span",
                          { key: item.id, staticClass: "product-img" },
                          [
                            _c("img", {
                              attrs: { src: item, alt: "" },
                              on: {
                                click: function($event) {
                                  return _vm.handlePreViewImg(row, index)
                                }
                              }
                            })
                          ]
                        )
                      }),
                      0
                    )
                  ])
                }
              },
              {
                key: "setup",
                fn: function(row) {
                  return _c("div", {}, [
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            return _vm.openModal(row)
                          }
                        }
                      },
                      [_vm._v("查看详情")]
                    ),
                    _c(
                      "a",
                      {
                        staticStyle: { "margin-left": "8px" },
                        on: {
                          click: function($event) {
                            return _vm.openModalSnapshot(row.orderId)
                          }
                        }
                      },
                      [_vm._v("快照")]
                    )
                  ])
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { width: 1200, centered: "", footer: null },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "div",
            { style: "max-height:" + _vm.ModalHeight + "px;overflow:auto;" },
            [
              _c("div", { staticClass: "modal-items" }, [
                _c("div", { staticClass: "title" }, [_vm._v("基本信息")]),
                _c("div", { staticClass: "modal-content" }, [
                  _c("div", { staticClass: "basic-item" }, [
                    _vm._v("订单编号："),
                    _c("span", { staticClass: "color-blue" }, [
                      _vm._v(_vm._s(_vm.orderProductInfoVo.orderNo))
                    ])
                  ]),
                  _c("div", { staticClass: "basic-item" }, [
                    _vm._v("买家："),
                    _c("span", { staticClass: "color-blue" }, [
                      _vm._v(_vm._s(_vm.orderProductInfoVo.buyerUserName))
                    ])
                  ]),
                  _c("div", { staticClass: "basic-item" }, [
                    _vm._v("卖家："),
                    _c("span", { staticClass: "color-blue" }, [
                      _vm._v(_vm._s(_vm.orderProductInfoVo.sellerUserName))
                    ])
                  ]),
                  _c("div", { staticClass: "basic-item" }, [
                    _vm._v(
                      "商户订单号：" +
                        _vm._s(_vm.orderProductInfoVo.requestNo || "")
                    )
                  ]),
                  _c("div", { staticClass: "basic-item" }, [
                    _vm._v(
                      "下单/截拍时间：" +
                        _vm._s(_vm.orderProductInfoVo.createTime || "")
                    )
                  ])
                ]),
                _c("div", { staticClass: "modal-content" }, [
                  _c("div", { staticClass: "basic-item" }, [
                    _vm._v("订单类型："),
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.orderProductInfoVo.orderKind == 0
                            ? "竞拍单"
                            : "一口价"
                        )
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "modal-content" }, [
                  _c("div", { staticClass: "basic-item" }, [
                    _vm._v("订单原价/中拍价："),
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v(_vm._s(_vm.orderProductInfoVo.orderPrice) + "元")
                    ])
                  ]),
                  _vm.orderProductInfoVo.status !== -1
                    ? _c("div", { staticClass: "basic-item" }, [
                        _vm._v("买家手续费："),
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(
                            _vm._s(_vm.orderProductInfoVo.buyerServiceMoney) +
                              "元"
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.orderProductInfoVo.status !== -1
                    ? _c("div", { staticClass: "basic-item" }, [
                        _vm._v("卖方手续费："),
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(
                            _vm._s(_vm.orderProductInfoVo.sellerServiceMoney) +
                              "元"
                          )
                        ])
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "basic-item" }, [
                    _vm._v("发货运费："),
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v(_vm._s(_vm.orderProductInfoVo.logisticsFee) + "元")
                    ])
                  ]),
                  _c("div", { staticClass: "basic-item" }, [
                    _vm._v("冻结保证金："),
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v(
                        _vm._s(_vm.orderProductInfoVo.deductDepositMoney || 0) +
                          "元"
                      )
                    ]),
                    _vm._v("（"),
                    _c("span", { staticClass: "color-red" }, [
                      _vm._v(_vm._s(_vm.orderProductInfoVo.depositReleaseDesc))
                    ]),
                    _vm._v("）")
                  ]),
                  _c("div", { staticClass: "basic-item" }, [
                    _vm._v("订单总金额："),
                    _c("span", { staticStyle: { color: "red" } }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.orderProductInfoVo.orderTotalPrice || 0) +
                            "元"
                        )
                      ])
                    ])
                  ])
                ]),
                _c("div", { staticClass: "dis-flex-start-start mt-20" }, [
                  _vm.orderProductInfoVo.payTime
                    ? _c("div", { staticClass: "modal-items" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v("支付信息")
                        ]),
                        _c(
                          "div",
                          { staticClass: "modal-content modal-express" },
                          [
                            _c("div", { staticClass: "basic-item" }, [
                              _vm._v("支付时间： "),
                              _c("span", { staticClass: "color-red" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.orderProductInfoVo.payTime || "未支付"
                                  )
                                )
                              ]),
                              _vm.orderProductInfoVo.status === 0
                                ? _c("span", [
                                    _vm._v("（倒计时"),
                                    _c("span", { staticClass: "color-red" }, [
                                      _vm._v(_vm._s("xxxx"))
                                    ]),
                                    _vm._v("）")
                                  ])
                                : _vm._e()
                            ]),
                            _c("div", { staticClass: "basic-item" }, [
                              _vm._v("货币支付："),
                              _c("span", { staticClass: "color-red" }, [
                                _vm._v(
                                  _vm._s(_vm.orderProductInfoVo.payMoney || "")
                                )
                              ]),
                              _vm._v("元")
                            ]),
                            _c("div", { staticClass: "basic-item" }, [
                              _vm._v(" 优惠支付："),
                              _vm.orderProductInfoVo.deductPaymentMoney
                                ? _c("span", { staticClass: "color-red" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderProductInfoVo
                                          .deductPaymentMoney + "元"
                                      )
                                    )
                                  ])
                                : _c("span", { staticClass: "color-red" }, [
                                    _vm._v("无")
                                  ]),
                              _vm._v("， 优惠方式: "),
                              _c("span", { staticClass: "color-red" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.orderProductInfoVo.deductPaymentDesc ||
                                      "无"
                                  )
                                )
                              ])
                            ]),
                            [1, 3, 4].includes(_vm.orderProductInfoVo.status) &&
                            false
                              ? _c(
                                  "div",
                                  { staticClass: "dis-flex-start" },
                                  [
                                    _c(
                                      "a-popconfirm",
                                      {
                                        attrs: {
                                          title: "确认主动退款么?",
                                          "ok-text": "Yes",
                                          "cancel-text": "No",
                                          okType: "danger"
                                        },
                                        on: {
                                          confirm: function($event) {
                                            return _vm.handleReturnAuctionOrder(
                                              _vm.orderReturnVo.id
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "a-button",
                                          { attrs: { type: "danger" } },
                                          [_vm._v("主动退款")]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "color-red ml-20" },
                                      [
                                        _vm._v(
                                          "（一键退款，本功能无需买家退货，谨慎操作）"
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.orderProductInfoVo.orderKind === 1
                              ? _c(
                                  "div",
                                  { staticClass: "dis-flex-start mt-20" },
                                  [
                                    _c(
                                      "a-popconfirm",
                                      {
                                        attrs: {
                                          title: "确认修改退款金额么？",
                                          "ok-text": "Yes",
                                          "cancel-text": "No"
                                        },
                                        on: {
                                          confirm: function($event) {
                                            return _vm.modifyRefundMoneyFunction(
                                              _vm.orderReturnVo.id,
                                              _vm.orderReturnVo
                                                .returnsReviewStatus
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "a-button",
                                          { attrs: { type: "primary" } },
                                          [_vm._v("修改退款金额")]
                                        )
                                      ],
                                      1
                                    ),
                                    _c("a-input", {
                                      staticStyle: {
                                        width: "120px",
                                        "margin-left": "10px",
                                        height: "30px"
                                      },
                                      attrs: {
                                        size: "small",
                                        placeholder: "输入退款金额"
                                      },
                                      model: {
                                        value: _vm.modifyRefundMoney,
                                        callback: function($$v) {
                                          _vm.modifyRefundMoney = $$v
                                        },
                                        expression: "modifyRefundMoney"
                                      }
                                    }),
                                    _c("a-textarea", {
                                      staticStyle: {
                                        width: "120px",
                                        "margin-left": "10px",
                                        height: "30px"
                                      },
                                      attrs: {
                                        rows: 2,
                                        placeholder: "备注原因"
                                      },
                                      model: {
                                        value: _vm.modifyRefundMark,
                                        callback: function($$v) {
                                          _vm.modifyRefundMark = $$v
                                        },
                                        expression: "modifyRefundMark"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        )
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "right-pay-message" })
                ])
              ]),
              _c("div", { staticClass: "modal-items mt-20" }, [
                _c("div", { staticClass: "title" }, [_vm._v("订单状态")]),
                _c("div", { staticClass: "modal-content modal-express" }, [
                  _c("div", { staticClass: "dis-flex-start-start" }, [
                    _c("div", { staticClass: "left-content" }, [
                      _c("div", { staticClass: "basic-item" }, [
                        _vm._v("订单主状态： "),
                        _c("span", { staticClass: "color-red" }, [
                          _vm._v(_vm._s(_vm.orderProductInfoVo.statusName))
                        ]),
                        _vm.orderProductInfoVo.orderRemainTime
                          ? _c("span", [
                              _vm._v(" （"),
                              _c("span", { staticClass: "color-red" }, [
                                _vm._v(
                                  _vm._s(_vm.orderProductInfoVo.orderRemainTime)
                                )
                              ]),
                              _vm._v("） ")
                            ])
                          : _vm._e()
                      ])
                    ]),
                    _c("div", { staticClass: "right-content ml-200" }, [
                      _vm.orderProductInfoVo.refundStatusName
                        ? _c("div", { staticClass: "basic-item" }, [
                            _c("span", [
                              _vm._v("逆向子状态："),
                              _c("span", { staticClass: "color-red" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.orderProductInfoVo.refundStatusName
                                  )
                                )
                              ])
                            ]),
                            _vm.orderProductInfoVo.orderReturnRemainTime
                              ? _c("span", [
                                  _vm._v(
                                    "（" +
                                      _vm._s(
                                        _vm.orderProductInfoVo
                                          .orderReturnRemainTime
                                      ) +
                                      "）"
                                  )
                                ])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ])
                  ])
                ])
              ]),
              _vm.orderProductInfoVo.orderKind == 0
                ? _c("div", { staticClass: "dis-flex-start-start mt-20" }, [
                    _c(
                      "div",
                      {
                        staticClass: "modal-items",
                        staticStyle: { width: "40%" }
                      },
                      [
                        _c("div", { staticClass: "title" }, [
                          _vm._v("买家侧显示赔付信息")
                        ]),
                        _c(
                          "div",
                          { staticClass: "modal-content modal-express" },
                          _vm._l(_vm.buyerCompensationInformation, function(
                            item,
                            index
                          ) {
                            return _c(
                              "div",
                              { key: index, staticClass: "express" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "col-sm-2 col-form-label" },
                                  [_vm._v(_vm._s(index))]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "col-sm-2 col-form-label color-red ml-20"
                                  },
                                  [_vm._v(_vm._s(item))]
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "modal-items",
                        staticStyle: { width: "40%" }
                      },
                      [
                        _c("div", { staticClass: "title" }, [
                          _vm._v("卖家侧显示赔付信息")
                        ]),
                        _c(
                          "div",
                          { staticClass: "modal-content modal-express" },
                          _vm._l(_vm.sellerCompensationInformation, function(
                            item,
                            index
                          ) {
                            return _c(
                              "div",
                              { key: index, staticClass: "express" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "col-sm-2 col-form-label" },
                                  [_vm._v(_vm._s(index))]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "col-sm-2 col-form-label color-red ml-20"
                                  },
                                  [_vm._v(_vm._s(item))]
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ]
                    )
                  ])
                : _vm._e(),
              _c("div", { staticClass: "dis-flex-start-start mt-20" }, [
                _c(
                  "div",
                  { staticClass: "modal-items", staticStyle: { width: "40%" } },
                  [
                    _c("div", { staticClass: "title" }, [
                      _vm._v("送评物流/凭证")
                    ]),
                    _c("div", { staticClass: "modal-content modal-express" }, [
                      _c("div", { staticClass: "express" }, [
                        _vm._v(" 物流公司："),
                        _c("span", { staticClass: "color-red" }, [
                          _vm._v(
                            _vm._s(_vm.orderGradeLogisticsVo.logisticsName)
                          )
                        ])
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "express",
                          on: {
                            click: function($event) {
                              return _vm.untils.copy_text(
                                _vm.orderGradeLogisticsVo.expressNo
                              )
                            }
                          }
                        },
                        [
                          _vm._v(" 物流单号："),
                          _c("span", { staticClass: "color-red" }, [
                            _vm._v(_vm._s(_vm.orderGradeLogisticsVo.expressNo))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "express" },
                        [
                          _c("span", [_vm._v("评级图片：")]),
                          _vm._l(_vm.orderReturnVo.gradeFile, function(
                            item,
                            key
                          ) {
                            return _c("img", {
                              key: key,
                              staticStyle: {
                                "max-width": "50px",
                                "max-height": "50px",
                                "margin-right": "5px"
                              },
                              attrs: { src: item },
                              on: {
                                click: function($event) {
                                  return _vm.previewImg(
                                    _vm.orderReturnVo.gradeFile
                                  )
                                }
                              }
                            })
                          })
                        ],
                        2
                      )
                    ])
                  ]
                ),
                _vm.showExpress
                  ? _c(
                      "div",
                      {
                        staticClass: "modal-items",
                        staticStyle: { width: "40%" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "title",
                            staticStyle: { width: "100%" }
                          },
                          [
                            _vm._v("退款/退货 "),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "20px" } },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v(
                                    "买家申请：" +
                                      _vm._s(
                                        _vm.orderReturnVo.receiptType ===
                                          "goodsreceived"
                                          ? "退货退款"
                                          : "仅退款"
                                      )
                                  )
                                ])
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "modal-content modal-express" },
                          [
                            _vm.orderProductInfoVo.orderKind == 0 &&
                            _vm.orderReturnVo.returnsReason
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "justify-content": "flex-start",
                                      "align-items": "center",
                                      "margin-bottom": "20px"
                                    }
                                  },
                                  [
                                    _c(
                                      "a-popconfirm",
                                      {
                                        attrs: {
                                          title: "确定是否扣除保证金？",
                                          "ok-text": "确定",
                                          "cancel-text": "取消"
                                        },
                                        on: {
                                          confirm: function($event) {
                                            return _vm.handleDeductBondPrice(
                                              _vm.orderReturnVo.id,
                                              1
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "a-button",
                                          { attrs: { type: "primary" } },
                                          [_vm._v("是否扣除保证金")]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-radio-group",
                                      {
                                        staticClass: "ml-20",
                                        attrs: {
                                          name: "radioGroup",
                                          "default-value": -1
                                        },
                                        on: {
                                          change: _vm.handleChangeRadioBondPrice
                                        }
                                      },
                                      [
                                        _c("a-radio", { attrs: { value: 1 } }, [
                                          _vm._v("扣除")
                                        ]),
                                        _c("a-radio", { attrs: { value: 0 } }, [
                                          _vm._v("不扣除")
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.orderProductInfoVo.orderKind == 0 &&
                            _vm.orderReturnVo.returnsReason
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "justify-content": "flex-start",
                                      "align-items": "center",
                                      "margin-bottom": "20px"
                                    }
                                  },
                                  [
                                    _c(
                                      "a-popconfirm",
                                      {
                                        attrs: {
                                          title: "确定是否扣手续费？",
                                          "ok-text": "确定",
                                          "cancel-text": "取消"
                                        },
                                        on: {
                                          confirm: function($event) {
                                            return _vm.handleDeductBondPrice(
                                              _vm.orderReturnVo.id,
                                              2
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "a-button",
                                          { attrs: { type: "danger" } },
                                          [_vm._v("是否扣除手续费")]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-radio-group",
                                      {
                                        staticClass: "ml-20",
                                        attrs: {
                                          name: "radioGroup",
                                          "default-value": -1
                                        },
                                        on: {
                                          change:
                                            _vm.handleChangeRadioHanldeFeels
                                        }
                                      },
                                      [
                                        _c("a-radio", { attrs: { value: 1 } }, [
                                          _vm._v("扣除")
                                        ]),
                                        _c("a-radio", { attrs: { value: 0 } }, [
                                          _vm._v("不扣除")
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.orderProductInfoVo.orderKind == 0 &&
                            _vm.orderReturnVo.returnsReason
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "justify-content": "flex-start",
                                      "align-items": "center",
                                      "margin-bottom": "20px"
                                    }
                                  },
                                  [
                                    _c(
                                      "a-popconfirm",
                                      {
                                        attrs: {
                                          title: "确定修改退款类型？",
                                          "ok-text": "确定",
                                          "cancel-text": "取消"
                                        },
                                        on: {
                                          confirm: function($event) {
                                            return _vm.handleReturnsAndRefundsType(
                                              _vm.orderReturnVo.id
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "a-button",
                                          { attrs: { type: "danger" } },
                                          [_vm._v("修改退货退款类型")]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-radio-group",
                                      {
                                        staticClass: "ml-20",
                                        attrs: {
                                          name: "radioGroup",
                                          "default-value": -1
                                        },
                                        on: {
                                          change:
                                            _vm.handleChangeRadioReturnAndRefundsType
                                        }
                                      },
                                      [
                                        _c(
                                          "a-radio",
                                          {
                                            attrs: { value: "goodsnotreceived" }
                                          },
                                          [_vm._v("仅退款")]
                                        ),
                                        _c(
                                          "a-radio",
                                          { attrs: { value: "goodsreceived" } },
                                          [_vm._v("退货退款")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.orderProductInfoVo.orderKind == 0 &&
                            _vm.orderReturnVo.returnsReason
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "justify-content": "flex-start",
                                      "align-items": "center",
                                      "margin-bottom": "20px"
                                    }
                                  },
                                  [
                                    _c(
                                      "a-popconfirm",
                                      {
                                        attrs: {
                                          title: "修改退货退款理由？",
                                          "ok-text": "确定",
                                          okType: "danger",
                                          "cancel-text": "取消"
                                        },
                                        on: {
                                          confirm: function($event) {
                                            return _vm.handleReturnsAndRefundsCauseType(
                                              _vm.orderReturnVo.id
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "a-button",
                                          { attrs: { type: "danger" } },
                                          [_vm._v("修改退货退款理由")]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-select",
                                      {
                                        staticStyle: {
                                          width: "200px",
                                          "margin-left": "10px"
                                        },
                                        attrs: {
                                          allowClear: "",
                                          placeholder: "选择修改类型"
                                        },
                                        on: {
                                          change:
                                            _vm.handleChangeSelectCauseType
                                        }
                                      },
                                      [
                                        _c(
                                          "a-select-option",
                                          { attrs: { value: "buyernoreason" } },
                                          [_vm._v("不想要了")]
                                        ),
                                        _c(
                                          "a-select-option",
                                          { attrs: { value: "productflaw" } },
                                          [_vm._v("瑕疵")]
                                        ),
                                        _c(
                                          "a-select-option",
                                          { attrs: { value: "sham" } },
                                          [_vm._v("假货")]
                                        ),
                                        _c(
                                          "a-select-option",
                                          {
                                            attrs: {
                                              value: "physicaldiscrepancy"
                                            }
                                          },
                                          [_vm._v("图片与实物不符")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c("div", { staticClass: "express" }, [
                              _vm._v("退款发起时间："),
                              _c("span", { staticClass: "color-red" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.orderReturnVo.returnSubmitTime || ""
                                  )
                                )
                              ])
                            ]),
                            _c("div", { staticClass: "express" }, [
                              _vm._v("申请退款金额："),
                              _c("span", { staticClass: "color-red" }, [
                                _vm._v(
                                  _vm._s(_vm.orderReturnVo.returnsAmount || "")
                                )
                              ])
                            ]),
                            !_vm.isEditorBaseInfoStatus
                              ? _c(
                                  "div",
                                  { staticClass: "express dis-flex-start" },
                                  [
                                    _c("div", [
                                      _c("div", { staticClass: "express" }, [
                                        _vm._v("退款原因："),
                                        _c(
                                          "span",
                                          { staticClass: "color-red" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.orderReturnVo
                                                  .returnsReason || ""
                                              )
                                            )
                                          ]
                                        )
                                      ]),
                                      _c("div", { staticClass: "express" }, [
                                        _c(
                                          "span",
                                          { staticClass: "color-red" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.orderReturnVo
                                                  .returnsOfService || ""
                                              )
                                            )
                                          ]
                                        )
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "express" },
                                        [
                                          _c("span", [_vm._v("附件图片：")]),
                                          _vm._l(
                                            _vm.orderReturnVo.returnsFile,
                                            function(item, key) {
                                              return _c("img", {
                                                key: key,
                                                staticStyle: {
                                                  "max-width": "50px",
                                                  "max-height": "50px",
                                                  "margin-right": "5px"
                                                },
                                                attrs: { src: item },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.previewImg(
                                                      _vm.orderReturnVo
                                                        .returnsFile
                                                    )
                                                  }
                                                }
                                              })
                                            }
                                          )
                                        ],
                                        2
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "express" },
                                        [
                                          _c("span", [_vm._v("送评图片：")]),
                                          _vm._l(
                                            _vm.orderReturnVo.gradeFile,
                                            function(item, key) {
                                              return _c("img", {
                                                key: key,
                                                staticStyle: {
                                                  "max-width": "50px",
                                                  "max-height": "50px",
                                                  "margin-right": "5px"
                                                },
                                                attrs: { src: item },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.previewImg(
                                                      _vm.orderReturnVo
                                                        .gradeFile
                                                    )
                                                  }
                                                }
                                              })
                                            }
                                          )
                                        ],
                                        2
                                      )
                                    ]),
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "a-button",
                                          {
                                            attrs: {
                                              type: "danger",
                                              size: "small"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.handleEditorBaseInfo(
                                                  _vm.orderReturnVo
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("编辑")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              : _c(
                                  "div",
                                  { staticClass: "express dis-flex-start" },
                                  [
                                    _c("div", [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "express dis-flex-start"
                                        },
                                        [
                                          _c("span", [_vm._v("退款原因：")]),
                                          _c("a-input", {
                                            staticStyle: { width: "300px" },
                                            attrs: {
                                              placeholder: "请输入退款原因"
                                            },
                                            model: {
                                              value: _vm.baseInfoRefundReason,
                                              callback: function($$v) {
                                                _vm.baseInfoRefundReason = $$v
                                              },
                                              expression: "baseInfoRefundReason"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "express" },
                                        [
                                          _c("span", [_vm._v("附件图片：")]),
                                          _c(
                                            "a-upload",
                                            {
                                              key: "",
                                              attrs: {
                                                action: _vm.ip + "/file/upload",
                                                "list-type": "picture-card",
                                                "file-list":
                                                  _vm.baseInfoReturnsFile
                                              },
                                              on: {
                                                preview: function($event) {
                                                  return _vm.handlePreview(
                                                    _vm.baseInfoGradeFile
                                                  )
                                                },
                                                change:
                                                  _vm.handleChangeReturnsFile
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  _c("a-icon", {
                                                    attrs: { type: "plus" }
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "ant-upload-text"
                                                    },
                                                    [_vm._v("上传图片")]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "express" },
                                        [
                                          _c("span", [_vm._v("送评图片：")]),
                                          _c(
                                            "a-upload",
                                            {
                                              key: "",
                                              attrs: {
                                                action: _vm.ip + "/file/upload",
                                                "list-type": "picture-card",
                                                "file-list":
                                                  _vm.baseInfoGradeFile
                                              },
                                              on: {
                                                preview: function($event) {
                                                  return _vm.handlePreview(
                                                    _vm.baseInfoGradeFile
                                                  )
                                                },
                                                change:
                                                  _vm.handleChangeGradeFile
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  _c("a-icon", {
                                                    attrs: { type: "plus" }
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "ant-upload-text"
                                                    },
                                                    [_vm._v("上传图片")]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]),
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "a-popconfirm",
                                          {
                                            attrs: {
                                              title: "确定修改？",
                                              "ok-text": "确定",
                                              "cancel-text": "取消"
                                            },
                                            on: {
                                              confirm: function($event) {
                                                return _vm.handleConfirmEditorBaseInfo(
                                                  _vm.orderReturnVo.id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "a-button",
                                              {
                                                attrs: {
                                                  type: "danger",
                                                  size: "small"
                                                }
                                              },
                                              [_vm._v("确定编辑")]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                ),
                            _vm.orderProductInfoVo.orderKind == 0 &&
                            _vm.orderReturnVo.returnsReason
                              ? _c("div", { staticClass: "express" }, [
                                  _c(
                                    "div",
                                    [
                                      _c("span", [_vm._v("运费：")]),
                                      _c("a-input", {
                                        staticStyle: { width: "80px" },
                                        model: {
                                          value: _vm.orderReturnVo.logisticsFee,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.orderReturnVo,
                                              "logisticsFee",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "orderReturnVo.logisticsFee"
                                        }
                                      }),
                                      _c(
                                        "a-popconfirm",
                                        {
                                          attrs: {
                                            title: "确定修改运费？",
                                            "ok-text": "确定",
                                            "cancel-text": "取消"
                                          },
                                          on: {
                                            confirm: function($event) {
                                              return _vm.modifyExtendFunction(
                                                _vm.orderReturnVo.id,
                                                "logisticsFee"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "a-button",
                                            {
                                              staticStyle: {
                                                "margin-left": "10px"
                                              },
                                              attrs: { type: "primary" }
                                            },
                                            [_vm._v("确定修改运费")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticStyle: { "margin-top": "5px" } },
                                    [
                                      _c("span", [_vm._v("额外费用：")]),
                                      _c("a-input", {
                                        staticStyle: { width: "80px" },
                                        model: {
                                          value:
                                            _vm.orderReturnVo.certificateMoney,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.orderReturnVo,
                                              "certificateMoney",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "orderReturnVo.certificateMoney"
                                        }
                                      }),
                                      _c(
                                        "a-popconfirm",
                                        {
                                          attrs: {
                                            title: "确定修改额外费用？",
                                            "ok-text": "确定",
                                            "cancel-text": "取消"
                                          },
                                          on: {
                                            confirm: function($event) {
                                              return _vm.modifyExtendFunction(
                                                _vm.orderReturnVo.id,
                                                "certificate"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "a-button",
                                            {
                                              staticStyle: {
                                                "margin-left": "10px"
                                              },
                                              attrs: { type: "primary" }
                                            },
                                            [_vm._v("确定修改额外费用")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ])
                              : _vm._e()
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              ]),
              _c("div", { staticClass: "modal-items mt-20" }, [
                _c("div", { staticClass: "title" }, [_vm._v("商品信息")]),
                _c("div", { staticClass: "modal-content modal-express" }, [
                  _c("div", { staticClass: "dis-flex-start-start" }, [
                    _c(
                      "div",
                      { staticClass: "left-content" },
                      [
                        _vm._l(_vm.productSnapshot.files, function(
                          item,
                          index
                        ) {
                          return _c("img", {
                            key: index,
                            staticStyle: {
                              "margin-right": "20px",
                              width: "30px"
                            },
                            attrs: { mode: "widthFix", src: item, alt: "" },
                            on: {
                              click: function($event) {
                                return _vm.handleShowBigImg(index)
                              }
                            }
                          })
                        }),
                        _c("div", { staticClass: "basic-item mt-20" }, [
                          _vm._v(
                            "尺寸：" + _vm._s(_vm.productSnapshot.productSize)
                          )
                        ]),
                        _c("div", { staticClass: "basic-item" }, [
                          _vm._v(
                            "评级公司：" +
                              _vm._s(
                                _vm.productSnapshot.ratingCompanyName || "无"
                              )
                          )
                        ]),
                        _c("div", { staticClass: "basic-item" }, [
                          _vm._v(
                            "评级编号：" +
                              _vm._s(
                                _vm.productSnapshot.ratingCompanyName || "无"
                              )
                          )
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "basic-item",
                            staticStyle: { color: "orange" }
                          },
                          [
                            _vm._v(" 承诺服务： "),
                            _vm._l(_vm.productSnapshot.serviceList, function(
                              item,
                              index
                            ) {
                              return _c("span", { key: index }, [
                                _vm._v(_vm._s(item.title) + "/")
                              ])
                            })
                          ],
                          2
                        )
                      ],
                      2
                    ),
                    _c("div", { staticClass: "right-content ml-20" }, [
                      _vm._v(
                        " " + _vm._s(_vm.productSnapshot.productName) + " "
                      )
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "dis-flex-start-start mt-20" }, [
                _c(
                  "div",
                  { staticClass: "modal-items", staticStyle: { width: "40%" } },
                  [
                    _c("div", { staticClass: "title" }, [_vm._v("发货与物流")]),
                    _c("div", { staticClass: "modal-content modal-express" }, [
                      _c("div", { staticClass: "express" }, [
                        _c("span", [
                          _vm._v(
                            "收件人：" +
                              _vm._s(_vm.orderLogisticsVo.consigneeRealname)
                          )
                        ]),
                        _c("span", { staticStyle: { "margin-left": "40px" } }, [
                          _vm._v(
                            "收件人电话：" +
                              _vm._s(_vm.orderLogisticsVo.consigneeTelphone)
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "express" }, [
                        _vm._v(
                          "详细地址：" +
                            _vm._s(_vm.orderLogisticsVo.consigneeAddress)
                        )
                      ]),
                      _c("div", { staticClass: "express" }, [
                        _vm._v(
                          "发货物流：" +
                            _vm._s(_vm.orderLogisticsVo.logisticsName)
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "express",
                          on: {
                            click: function($event) {
                              return _vm.untils.copy_text(
                                _vm.orderLogisticsVo.expressNo
                              )
                            }
                          }
                        },
                        [
                          _vm._v(
                            "物流单号：" +
                              _vm._s(_vm.orderLogisticsVo.expressNo)
                          )
                        ]
                      )
                    ])
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "modal-items", staticStyle: { width: "40%" } },
                  [
                    _c("div", { staticClass: "title" }, [_vm._v("退货物流")]),
                    _c(
                      "div",
                      { staticClass: "modal-content modal-express" },
                      [
                        _c("div", { staticClass: "express" }, [
                          _c("span", [
                            _vm._v(
                              "收件人：" +
                                _vm._s(_vm.orderReturnVo.consigneeRealname)
                            )
                          ]),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "40px" } },
                            [
                              _vm._v(
                                "收件人电话：" +
                                  _vm._s(_vm.orderReturnVo.consigneeTelphone)
                              )
                            ]
                          )
                        ]),
                        _c("div", { staticClass: "express" }, [
                          _vm._v(
                            "详细地址：" +
                              _vm._s(_vm.orderReturnVo.consigneeAddress)
                          )
                        ]),
                        _c("div", { staticClass: "express" }, [
                          _vm._v(
                            "退货物流：" +
                              _vm._s(_vm.orderReturnVo.logisticsName)
                          )
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "express",
                            on: {
                              click: function($event) {
                                return _vm.untils.copy_text(
                                  _vm.orderReturnVo.expressNo
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              "物流单号：" + _vm._s(_vm.orderReturnVo.expressNo)
                            )
                          ]
                        ),
                        _c("div", { staticClass: "express" }, [
                          _vm._v("物流轨迹：")
                        ]),
                        _vm._l(_vm.travels, function(item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "order-log" },
                            [
                              _c("div", [_vm._v(_vm._s(item.time))]),
                              _c("div", [_vm._v(_vm._s(item.context))])
                            ]
                          )
                        })
                      ],
                      2
                    )
                  ]
                )
              ]),
              [2, 5].includes(_vm.orderReturnVo.returnsReviewStatus) ||
              _vm.orderProductInfoVo.status == 3
                ? _c("div", { staticClass: "modal-items" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("售后操作")]),
                    _c(
                      "div",
                      { staticClass: "modal-content" },
                      [
                        _c("a-textarea", {
                          staticStyle: { width: "500px" },
                          attrs: {
                            placeholder: "请输入操作原因",
                            "auto-size": ""
                          },
                          model: {
                            value: _vm.remarkText,
                            callback: function($$v) {
                              _vm.remarkText = $$v
                            },
                            expression: "remarkText"
                          }
                        })
                      ],
                      1
                    ),
                    _vm.orderReturnVo.returnsReviewStatus == 2
                      ? _c(
                          "div",
                          { staticClass: "dis-flex-start mt-20" },
                          [
                            _c(
                              "a-popconfirm",
                              {
                                attrs: {
                                  title: "确认同意退货申请么？",
                                  "ok-text": "Yes",
                                  "cancel-text": "No"
                                },
                                on: {
                                  confirm: function($event) {
                                    return _vm.operationRefund(
                                      _vm.orderReturnVo.returnsNo,
                                      3
                                    )
                                  }
                                }
                              },
                              [
                                _c("a-button", { attrs: { type: "primary" } }, [
                                  _vm._v("同意退货申请")
                                ])
                              ],
                              1
                            ),
                            _c("span", { staticClass: "color-red ml-20" }, [
                              _vm._v(
                                "（替卖家同意退货，同意后，买家将看见退货地址，逆向状态将是等待买家退货）"
                              )
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.orderReturnVo.returnsReviewStatus == 2
                      ? _c(
                          "div",
                          { staticClass: "dis-flex-start mt-20" },
                          [
                            _c(
                              "a-popconfirm",
                              {
                                attrs: {
                                  title: "确认拒绝退货申请么？",
                                  "ok-text": "Yes",
                                  "cancel-text": "No"
                                },
                                on: {
                                  confirm: function($event) {
                                    return _vm.operationRefund(
                                      _vm.orderReturnVo.returnsNo,
                                      -1
                                    )
                                  }
                                }
                              },
                              [
                                _c("a-button", { attrs: { type: "primary" } }, [
                                  _vm._v("拒绝退货申请")
                                ])
                              ],
                              1
                            ),
                            _c("span", { staticClass: "color-red ml-20" }, [
                              _vm._v(
                                "（替卖家拒绝退货，拒绝后，买家将不能再次发起退货申请，谨慎操作！）"
                              )
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.orderProductInfoVo.status == 3
                      ? _c(
                          "div",
                          { staticClass: "dis-flex-start mt-20" },
                          [
                            _c(
                              "a-popconfirm",
                              {
                                attrs: {
                                  title: "确认代替买家同意收货么？",
                                  "ok-text": "Yes",
                                  "cancel-text": "No"
                                },
                                on: {
                                  confirm: function($event) {
                                    return _vm.operationRefund(
                                      _vm.orderReturnVo.returnsNo,
                                      10
                                    )
                                  }
                                }
                              },
                              [
                                _c("a-button", { attrs: { type: "primary" } }, [
                                  _vm._v("替买家同意收货")
                                ])
                              ],
                              1
                            ),
                            _c("span", { staticClass: "color-red ml-20" }, [
                              _vm._v("（TODO 待开发，目前还是保持之前的逻辑）")
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.orderProductInfoVo.status == 3
                      ? _c(
                          "div",
                          { staticClass: "dis-flex-start mt-20" },
                          [
                            _c(
                              "a-popconfirm",
                              {
                                attrs: {
                                  title: "确认代替买家拒绝收货么？",
                                  "ok-text": "Yes",
                                  "cancel-text": "No"
                                },
                                on: {
                                  confirm: function($event) {
                                    return _vm.operationRefund(
                                      _vm.orderReturnVo.returnsNo,
                                      11
                                    )
                                  }
                                }
                              },
                              [
                                _c("a-button", { attrs: { type: "primary" } }, [
                                  _vm._v("替买家拒绝收货")
                                ])
                              ],
                              1
                            ),
                            _c("span", { staticClass: "color-red ml-20" }, [
                              _vm._v("（TODO 待开发，目前还是保持之前的逻辑）")
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    [1, 3, 4, 5].includes(_vm.orderReturnVo.returnsReviewStatus)
                      ? _c(
                          "div",
                          { staticClass: "dis-flex-start mt-20" },
                          [
                            _c(
                              "a-popconfirm",
                              {
                                attrs: {
                                  title: "确认替卖家同意收货么？",
                                  "ok-text": "Yes",
                                  "cancel-text": "No"
                                },
                                on: {
                                  confirm: function($event) {
                                    return _vm.operationRefund(
                                      _vm.orderReturnVo.returnsNo,
                                      6
                                    )
                                  }
                                }
                              },
                              [
                                _c("a-button", { attrs: { type: "primary" } }, [
                                  _vm._v("替卖家同意收货")
                                ])
                              ],
                              1
                            ),
                            _c("span", { staticClass: "color-red ml-20" }, [
                              _vm._v(
                                "（替卖家确认收货，确认后，退货完成，款项原路退给买家）"
                              )
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    [1, 3, 4, 5].includes(_vm.orderReturnVo.returnsReviewStatus)
                      ? _c(
                          "div",
                          { staticClass: "dis-flex-start mt-20" },
                          [
                            _c(
                              "a-popconfirm",
                              {
                                attrs: {
                                  title: "确认替卖家拒绝确认收货？",
                                  "ok-text": "Yes",
                                  "cancel-text": "No"
                                },
                                on: {
                                  confirm: function($event) {
                                    return _vm.operationRefund(
                                      _vm.orderReturnVo.returnsNo,
                                      7
                                    )
                                  }
                                }
                              },
                              [
                                _c("a-button", { attrs: { type: "primary" } }, [
                                  _vm._v(" 替卖家拒绝确认收货 ")
                                ])
                              ],
                              1
                            ),
                            _c("span", { staticClass: "color-red ml-20" }, [
                              _vm._v(
                                "（替卖家拒绝确认收货，拒绝后，订单将重回正向流程）"
                              )
                            ])
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                : _vm._e(),
              _c("div", { staticClass: "modal-items mt-20" }, [
                _c("div", { staticClass: "title" }, [_vm._v("退货退款日志")]),
                _c(
                  "div",
                  { staticClass: "modal-content modal-express" },
                  _vm._l(_vm.orderLogVo, function(item, index) {
                    return _c("div", { key: index, staticClass: "order-log" }, [
                      _c("div", [_vm._v(_vm._s(item.createTime))]),
                      _c("div", [_vm._v(_vm._s(item.userName))]),
                      item.content
                        ? _c("div", [_vm._v("内容:" + _vm._s(item.content))])
                        : _vm._e(),
                      item.files !== undefined &&
                      item.files != null &&
                      item.files.length > 0
                        ? _c(
                            "div",
                            { staticClass: "express" },
                            [
                              _c("span", [_vm._v("附加图片：")]),
                              _vm._l(item.files, function(item, key) {
                                return _c("img", {
                                  key: key,
                                  staticStyle: {
                                    "max-width": "50px",
                                    "max-height": "50px",
                                    "margin-right": "5px"
                                  },
                                  attrs: { src: item },
                                  on: {
                                    click: function($event) {
                                      return _vm.previewImg(item.files)
                                    }
                                  }
                                })
                              })
                            ],
                            2
                          )
                        : _vm._e(),
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            item.type == 1 || item.type == 2
                              ? "系统提示"
                              : item.type == 3
                              ? "后台操作"
                              : "备注"
                          ) +
                            "：" +
                            _vm._s(item.statusMessage || "")
                        )
                      ])
                    ])
                  }),
                  0
                )
              ])
            ]
          )
        ]
      ),
      _c(
        "a-modal",
        {
          attrs: { width: 500, centered: "", footer: null },
          model: {
            value: _vm.snapshotshow,
            callback: function($$v) {
              _vm.snapshotshow = $$v
            },
            expression: "snapshotshow"
          }
        },
        [
          _c(
            "div",
            { style: "max-height:" + _vm.ModalHeight + "px;overflow:auto;" },
            [
              _c("div", { staticClass: "modal-items" }, [
                _c("div", { staticClass: "title" }, [_vm._v("快照信息")]),
                _c("div", { staticClass: "modal-content modal-express" }, [
                  _c("div", { staticClass: "basic-item" }, [
                    _vm._v("商品编号：" + _vm._s(_vm.productVersion.productNo))
                  ]),
                  _c("div", { staticClass: "basic-item" }, [
                    _vm._v(
                      "商品名称：" +
                        _vm._s(_vm.productVersion.productName || "")
                    )
                  ]),
                  _c("div", { staticClass: "basic-item" }, [
                    _vm._v("商品价格："),
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("￥" + _vm._s(_vm.productVersion.finalPrice))
                    ])
                  ]),
                  _c("div", { staticClass: "basic-item" }, [
                    _vm._v("运费："),
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("￥" + _vm._s(_vm.productVersion.logisticsFee))
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "express" },
                    [
                      _c("span", [_vm._v("图片：")]),
                      _vm._l(_vm.productVersion.files, function(item, key) {
                        return _c("img", {
                          key: key,
                          staticStyle: {
                            "max-width": "50px",
                            "max-height": "50px",
                            "margin-right": "5px"
                          },
                          attrs: { src: item },
                          on: {
                            click: function($event) {
                              return _vm.previewImg(_vm.productVersion.files)
                            }
                          }
                        })
                      })
                    ],
                    2
                  ),
                  _c(
                    "div",
                    { staticClass: "basic-item" },
                    [
                      _vm._v(" 商品服务： "),
                      _vm._l(_vm.productVersion.serviceList, function(
                        tag,
                        index
                      ) {
                        return _c(
                          "a-tag",
                          { key: index, attrs: { color: "blue" } },
                          [_vm._v(" " + _vm._s(tag.title) + " ")]
                        )
                      })
                    ],
                    2
                  )
                ])
              ])
            ]
          )
        ]
      ),
      _c("UserChatListModel", { ref: "chat-list" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }